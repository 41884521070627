import Vue from "vue";
import VueRouter from "vue-router";
import beforeEach from "./beforeEach";
import Main from "../modules/main/Main.vue";
import Auth from "../modules/auth/Auth.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: { name: "Home" },
  },
  {
    path: "/",
    name: "Main",
    redirect: { name: "Home" },
    component: Main,
    children: [
      {
        path: "home",
        name: "Home",
        redirect: { name: "Overview" },
        component: () => import("@/modules/main/views/home/Home.vue"),
        children: [
          {
            path: "overview",
            name: "Overview",
            meta: {
              title: "Overview",
              requiresPermission: "account-exchanges",
              requiresAuth: true,
            },
            component: () =>
              import(
                "@/modules/main/views/home/children/overview/Overview.vue"
              ),
          },
          {
            path: "account-details/:id",
            name: "AccountDetails",
            redirect: { name: "AccountSummary" },
            component: () =>
              import(
                "@/modules/main/views/home/children/accountDetails/AccountDetails.vue"
              ),
            children: [
              {
                path: "summary",
                name: "AccountSummary",
                meta: {
                  title: "Detalhes da conta",
                  requiresPermission: "account-exchanges",
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/modules/main/views/home/children/accountDetails/children/summary/Summary.vue"
                  ),
              },
              {
                path: "stats",
                name: "AccountStats",
                meta: {
                  title: "Estatísticas",
                  requiresPermission: "account-exchanges",
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/modules/main/views/home/children/accountDetails/children/stats/Stats.vue"
                  ),
              },
              {
                path: "edit",
                name: "EditAccount",
                meta: {
                  title: "Editar Conta API",
                  requiresPermission: "account-exchanges",
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/modules/main/views/home/children/accountDetails/children/editAccount/EditAccount.vue"
                  ),
              },
              {
                path: "trades",
                name: "Trades",
                meta: {
                  title: "Transações da conta",
                  requiresPermission: "account-exchanges",
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/modules/main/views/home/children/accountDetails/children/trades/Trades.vue"
                  ),
              },
              {
                path: "open-orders",
                name: "AccountOpenOrders",
                meta: {
                  title: "Ordens abertas",
                  requiresPermission: "account-exchanges",
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/modules/main/views/home/children/accountDetails/children/openOrders/OpenOrders.vue"
                  ),
              },
              {
                path: "open-positions",
                name: "AccountOpenPositions",
                meta: {
                  title: "Posições abertas",
                  requiresPermission: "account-exchanges",
                  requiresAuth: true,
                },
                component: () =>
                  import(
                    "@/modules/main/views/home/children/accountDetails/children/openPositions/OpenPositions.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "connect-exchange",
        name: "ConnectExchange",
        meta: {
          title: "Conectar Conta API",
          requiresAuth: true,
          requiresPermission: "account-exchanges",
        },
        component: () =>
          import("@/modules/main/views/connectExchange/ConnectExchange.vue"),
      },
      {
        path: "algorithms",
        name: "Algorithms",
        component: () =>
          import("@/modules/main/views/algorithms/Algorithms.vue"),
        children: [
          {
            path: "",
            name: "Algorithms",
            meta: {
              title: "Algoritmos",
              requiresAuth: true,
              requiresPermission: "algorithms",
            },
            component: () =>
              import(
                "@/modules/main/views/algorithms/children/marketplace/Marketplace.vue"
              ),
          },
        ],
      },
    ],
  },
  {
    path: "/auth",
    name: "Auth",
    redirect: { name: "SignIn" },
    component: Auth,
    children: [
      {
        path: "sign-in",
        name: "SignIn",
        meta: { title: "Entrar", preventAuth: true },
        component: () => import("@/modules/auth/views/signIn/SignIn.vue"),
      },
      {
        path: "sign-up",
        name: "SignUp",
        meta: { title: "Criar Conta", preventAuth: true },
        component: () => import("@/modules/auth/views/signUp/SignUp.vue"),
      },
    ],
  },
  {
    path: "*",
    redirect: { name: "Home" },
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(beforeEach);

export default router;
