<template>
  <v-layout class="main-wrapper" column>
    <v-app-bar color="transparent" flat>
      <v-btn class="btn-icon" icon @click="handleDrawer()">
        <v-icon>mdi-menu</v-icon>
      </v-btn>

      <v-toolbar-title class="pl-2">
        {{ _currentDate }}
      </v-toolbar-title>

      <v-spacer />

      <v-avatar size="2.5rem" color="buttonColor">
        <v-icon color="white">mdi-account-outline</v-icon>
      </v-avatar>
    </v-app-bar>

    <v-navigation-drawer
      v-if="_routes.length"
      v-model="drawer"
      class="side-drawer"
      color="neutral1"
      :mini-variant="$vuetify.breakpoint.lgAndUp"
      floating
      app
    >
      <div class="d-flex flex-column fill-height">
        <div class="d-flex justify-center py-6">
          <v-img
            :src="
              require(`@/assets/lgbanking/${
                $vuetify.breakpoint.mdAndDown ? 'logotype' : 'logomark'
              }.png`)
            "
            :max-width="$vuetify.breakpoint.mdAndDown ? '8rem' : '2.5rem'"
            contain
          />
        </div>

        <v-list class="py-0" color="transparent" dense nav>
          <router-link
            v-for="(item, i) in _routes"
            v-slot="{ href, navigate, isActive }"
            :to="item.path"
            :key="i"
            custom
          >
            <v-list-item class="mb-2" :href="href" @click="navigate">
              <v-list-item-icon class="mr-2">
                <v-icon :color="isActive ? 'primary' : 'neutral5'">
                  {{ item.icon }}
                </v-icon>
              </v-list-item-icon>

              <v-list-item-title :class="{ 'primary--text': isActive }">
                {{ item.label }}
              </v-list-item-title>
            </v-list-item>
          </router-link>
        </v-list>
      </div>

      <template v-slot:append>
        <v-list class="transparent" dense nav>
          <v-list-item @click="signOut()">
            <v-list-item-icon class="mr-2">
              <v-icon color="neutral5"> mdi-logout </v-icon>
            </v-list-item-icon>

            <v-list-item-title> Sair </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <router-view />
  </v-layout>
</template>

<script>
import { mapGetters } from "vuex";
import { currentDate } from "@/helpers";

export default {
  name: "Main",

  data() {
    return {
      drawer: true,
    };
  },

  computed: {
    ...mapGetters({
      _user: "user",
    }),

    _routes() {
      const routes = [
        {
          icon: "mdi-view-dashboard-outline",
          label: "Início",
          path: "/home",
          permission: this.$can("account-exchanges"),
        },
        {
          icon: "mdi-code-block-braces",
          label: "Algoritmos",
          path: "/algorithms",
          permission: this.$can("algorithms"),
        },
      ];

      return routes.filter((el) => el.permission ?? true);
    },

    _currentDate() {
      return currentDate("dddd, DD MMM YYYY");
    },
  },

  methods: {
    handleDrawer() {
      this.drawer = !this.drawer;
    },

    signOut() {
      this.$root.$emit("sign-out");
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
