import dayjs from "dayjs";
const utc = require("dayjs/plugin/utc");
const timezone = require("dayjs/plugin/timezone");
require("dayjs/locale/pt");

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale("pt");

export function formatDate(value, formatString = "DD/MM/YYYY HH:mm:ss") {
  if (!value) return "";

  const date = new Date(value.toString());
  return dayjs(date).format(formatString);
}

export function currentDate(formatString = "DD/MM/YYYY HH:mm:ss") {
  const date = new Date();
  return dayjs(date).format(formatString);
}

export function formatTimezoneDate(
  value,
  formatString = "DD/MM/YYYY HH:mm:ss",
  timezone = "America/Sao_Paulo"
) {
  if (!value) return "";

  const date = new Date(value.toString());
  return dayjs(date).tz(timezone).format(formatString);
}
