export function formatAmount(value, decimal = 2) {
  if (!value) value = 0;

  const og = Math.pow(10, decimal);
  value = Math.floor(value * og) / og;

  return Number(value.toFixed(decimal));
}

export function currencyMask(value, min = 2, max = 2) {
  value = formatAmount(value, min);

  return value.toLocaleString("pt-BR", {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  });
}

export function parseNumber(value) {
  return Number(value.replace(/\./g, "").replace(/,/g, "."));
}

export function capitalizeSentence(value) {
  if (!value) return "";

  const values = value.split(" ");

  return values
    .map((e) => capitalizeWord(e))
    .toString()
    .replaceAll(",", " ");
}

export function capitalizeWord(value) {
  if (!value) return "";

  return (
    value.toString().charAt(0).toUpperCase() +
    value.toString().substring(1).toLowerCase()
  );
}
